import './App.css';
import axios from 'axios'
import {useEffect, useState} from "react";
import Coin from "./Coin";
import './coin.css'

function App() {

    const [coins, setCoins] = useState([])
    const[search, setSearch] = useState('')

    useEffect(() => {
        axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=100&page=1&sparkline=false', {
            headers: {
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        }).then(res => {
            setCoins(res.data)

        }).catch(error => console.log(error))
    }, [])


    const handleChange = (e) => {
      setSearch(e.target.value)
    }
    
    const filteredCoins = coins.filter(coin =>
    coin.name.toLowerCase().includes(search.toLowerCase())
    )
      
const notReload = (e) => {
    e.preventDefault();

}
    return (
        <div className="coin-app">
            <div className={"coin-search"}>
                <h1 className={"coin-search"}>Search a Currency</h1>
                    <form onSubmit={notReload}>
                        <input type="text" placeholder={"search"} className="coin-input" onChange={handleChange}/>
                    </form>

            </div>
            {filteredCoins.map(coin => {
                return (
                    <Coin
                        key = {coin.id}
                        name = {coin.name}
                        image = {coin.image}
                        symbol={coin.symbol}
                        marketcap={coin.market_cap}
                        price={coin.current_price}
                        priceChange={coin.price_change_percentage_24h}
                        volume={coin.total_volume}
                    />
                )
            })}
        </div>
    );
}

export default App;
